import '../styles/ModalRelease.css';

function ModalRelease({showModal, setShowModal}) {

    function onClose() {
        setShowModal(!showModal)
    }

	return (
		<div className="modal-overlay">
            <div className="modal">
                <h1>Release Notes</h1>
                <div className='modal-rev'>
                    <h2>24/07/2024</h2>
                    <h3>Game Mode</h3>
                    <p>Mode "Game" added</p>
                    Catégories : 
                    <ul>
                        <li>Sales (Milions) : <a href='https://vgsales.fandom.com/wiki/The_Legend_of_Zelda' target='_blank' rel='noreferrer'>VGSALES</a></li>
                        <li>Notes : <a href='https://www.metacritic.com/' target='_blank' rel='noreferrer'>Metacritic</a></li>
                        <li>Type : Principal, Hors série, Remake</li> 
                    </ul>
                </div>

                <hr /> 

                <div className='modal-rev'>
                    <h2>15/07/2024</h2>
                    <h2>Item Mode</h2>
                    <h3>Games includes :</h3>
                    <p>
                        <ul>
                            <li>Ocarina of Time (OOT)</li>
                        </ul>
                    </p>
                </div>
                <img src='../assets/close.png' alt='close' className="close-button" onClick={onClose} />
            </div>
        </div>
	)
}

export default ModalRelease
