import '../styles/Prev.css';
import '../styles/Grille.css';
import { useState, useEffect } from 'react'

function Prev(gameMode) {

	const [guessPrev, setGuessPrev] = useState([]);


    useEffect(() => {
        let url;
        switch (gameMode.gameMode) {
            case 'item':
                url = 'items-prev';
                break;
            case 'game':
                url = 'games-prev';
                break; 
            default:
                url = 'items-prev';
        }
        console.log(url);
        fetch(`https://zeldle.lps-consulting.fr/api/${url}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(guessPrev => { setGuessPrev(guessPrev); })
            .catch(error => { console.error('Error fetching data:', error); });
    }, [gameMode]);


	return (
		<div className="grille">
			<h2>Yesterday's game</h2>
			<div className="grille-row">
                { 
                    gameMode.gameMode === 'item' ?
                        guessPrev.map(item => (                        
                            <div className='grille-cell' key={item.id_item}><img src={`assets/${item.img_item}`} alt={item.nom_item} />{item.nom_item}</div>
                        ))
                    : gameMode.gameMode === 'game' ?
                    guessPrev.map(game => (                        
                            <div className='grille-cell' key={game.id_jeu}><img src={`assets/${game.img_jeu}`} alt={game.nom_jeu} />{game.nom_jeu}</div>
                        ))
                    : null
                }
			</div>
		</div>
	)
}

export default Prev
