import React, { useState, useEffect } from "react";
import "../styles/Countdown.css";

const Countdown = () => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const nextMidnight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1, // Le jour suivant
      0,
      0,
      0,
      0 // Minuit
    );
    const difference = nextMidnight - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="countdown">
      <h3>Next Try in : </h3>
      <div>
        {timeLeft.hours !== undefined ? (
          <span>{`${formatTime(timeLeft.hours)} : ${formatTime(
            timeLeft.minutes
          )} : ${formatTime(timeLeft.seconds)}`}</span>
        ) : (
          <span>Minuit est passé!</span>
        )}
      </div>
    </div>
  );
};

export default Countdown;
