import { Link } from 'react-router-dom';
import '../styles/Home.css';

function Home() {

return (
    <div className='home'>
        <h2>Game mode</h2>
        <ul>
            <li><Link className="home-item" to="/item">Item</Link></li>
            <li><Link className="home-item"to="/game">Game</Link></li>
        </ul>
    </div>
	)
}

export default Home
