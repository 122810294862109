import '../styles/Footer.css';

function Footer() {
	return (
		<div className="footer">
            <a href="https://lps-consulting.fr" target="_blank" rel="noreferrer">LPS CONSULTING</a> - 2024
        </div>
	)
}

export default Footer
