import '../styles/Input.css';
import { useState, useEffect } from 'react'
import Countdown from './Countdown.js'
import fullHeartLogo from '../assets/full_heart.png'
import halfHeartLogo from '../assets/half_heart.png'
import emptyHeartLogo from '../assets/empty_heart.png'
import winLogo from '../assets/win1.gif'
import link from '../assets/link.png'

function Input({rows, rowsUpdate, itemNow, itemFind, nbTestMax, nbTest, dateFormatee, gameMode}) {   
    const fullHeart = (10-nbTest)/2
    const halfHeart = nbTest%2
    const emptyHeart = nbTest/2
    console.log('rooow', rows)

    function myFunction() {
		document.getElementById("dropdown-input").classList.toggle("show");
    }
    function handleBlur() {
        document.getElementById("dropdown-input").classList.toggle("show");
    }

    function filterFunction() {
        var input, filter, a, i;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        var div = document.getElementById("dropdown-input");
        a = div.getElementsByClassName("dropdown-input-div");
        var found = false; // Variable pour suivre si un élément correspondant est trouvé
    
        for (i = 0; i < a.length; i++) {
            var txtValue = a[i].textContent || a[i].innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                a[i].style.display = "";
                found = true; // On a trouvé au moins un élément correspondant
            } else {
                a[i].style.display = "none";
            }
        }
    
        // Vérifier si aucun élément n'a été trouvé et afficher "Pas d'objet trouvé"
        if (!found) {
            var noResult = document.createElement("div");
            noResult.textContent = "objet introuvable";
            noResult.classList.add("no-results"); // Ajouter une classe pour le style CSS si nécessaire
            div.appendChild(noResult); // Ajouter le message à votre div
        } else {
            // S'il y avait précédemment un message "Pas d'objet trouvé", le supprimer
            var noResultMessage = div.querySelector(".no-results");
            if (noResultMessage) {
                div.removeChild(noResultMessage);
            }
        }
    }

    function addInput(dateFormatee, id, item, game, anneeJeu, type, color, location, plateforme_jeu, img_item){
        console.log('rooow', rows)
        var rowsLength = rows.length + 1
        var rowAdd = {dateFormatee, gameMode, rowsLength, id, item, game, anneeJeu, type, color, location, plateforme_jeu, img_item}
        console.log(rowAdd);

        rowsUpdate ([
            rowAdd, ...rows
        ])
        
        localStorage.setItem('rows-item', JSON.stringify(rows))
        // majData (rows)
    
    }

    function viderLocalStorage(key){
        localStorage.removeItem(key)
        rowsUpdate ([])
    }

    const [data, setData] = useState([]);
    useEffect(() => {
        // console.log('Fetching data from API...');
        fetch('https://zeldle.lps-consulting.fr/api/items')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => { 
                setData(data); 
                majData (rows, data)
            })
            .catch(error => { console.error('Error fetching data:', error); });
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const newData = data.filter(item => !rows.some(row => row.id === item.id_item));
            setData(newData);
        }
    }, [rows]);

    function majData (rows, data) {
        if (data.length > 0) {
            const newData = data.filter(item => !rows.some(row => row.id === item.id_item));
            setData(newData);
        }
    }


	return (
        
		<div className="saisie">
            { itemFind === 1 ?
                <div>
                    <div className="saisie-title">
                        <h2>You Won!</h2>
                        <img src={winLogo} alt='win' />
                    </div>
                    <div className='saisie-item-find'>
                        <img src={`assets/${itemNow.img_item}`} alt={itemNow.nom_item} /><br />
                        {itemNow.nom_item}
                    </div>
                    { /*<div className="grille">
                        <div className="grille-row">
                                <div className='grille-cell' key={itemNow.id_item}><img src={`assets/${itemNow.img_item}`} alt={itemNow.nom_item} />{itemNow.nom_item}</div>
                        </div>
                    </div> */ }
                    
                    <Countdown />
                    <br />
                    <button onClick={() => viderLocalStorage('rows')} >Recommencer</button>
                </div>
            : nbTestMax === 0 ? 
                <div>
                    <h2>Guess today's item..</h2>
                    <div className="life">
                    {/*    {nbTest}/10 */}
                    <img src={link} alt="link" className='link' />
                    {/* Utilisation de map pour générer une liste d'éléments */}
                    {Array.from({ length: fullHeart }, (_, index) => (
                        <img key={`${index}-fullheart`} src={fullHeartLogo} alt="fullHeart" className='heart' />
                    ))}
                    {Array.from({ length: halfHeart }, (_, index) => (
                        <img key={`${index}-halfHeart`} src={halfHeartLogo} alt="halfHeart" className='heart' />
                    ))}
                    {Array.from({ length: emptyHeart }, (_, index) => (
                        <img key={`${index}-emptyHeart`} src={emptyHeartLogo} alt="emptyHeart" className='heart' />
                    ))}
                    </div>
                    <div className="dropdown">
                        <div id="myDropdown" className="dropdown-content" >
                            <input type="text" placeholder="Search an item..." id="myInput" className="dropdown-content"  onKeyUp={filterFunction} onFocus={myFunction} onBlur={handleBlur} />
                            <div className="dropdown-input" id="dropdown-input" >
                            {data.map(item => (
                                <div key={`${item.id_item}-div`} className='dropdown-input-div' onMouseDown={() => addInput(dateFormatee, item.id_item, item.nom_item, item.nom_jeu, item.annee_jeu, item.type_item, item.couleur_item, item.localisation_item, item.plateforme_jeu, item.img_item)}>
                                    <div key={`${item.id_item}-item`} className='dropdown-input-item'   >
                                        <div className='dropdown-input-item-img'> 
                                            <img key={`${item.id_item}-img`} src={`assets/${item.img_item}`} alt="img_item" className='dropdown-input-item-img' />
                                        </div>
                                        <span>{item.nom_item} ({item.acronyme_jeu})</span>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <br />
                    
                    { /* <button onClick={() => addInput(123, 'Ocarina du temps', 'OOT', 'Main', 'Bleu', 'Hyrule Field')} >Ajouter OOT</button>
                    <button onClick={() => addInput(456, 'Grappin', 'TP', 'Main', 'noir', 'Donjon')} >Ajouter2 TP</button> */ }
                    { /* <button onClick={() => viderLocalStorage('rows')} >Vider</button> */ }
                </div>
                : <div>
                    <h2>You Loose..</h2>
                    L'objet était 
                    <div className="grille">
                        <div className="grille-row">
                            <div className='grille-cell' key={itemNow.id_item}><img src={`assets/${itemNow.img_item}`} alt={itemNow.nom_item} />{itemNow.nom_item}</div>
                        </div>
                        <Countdown />
                        <br />                        
                        <button onClick={() => viderLocalStorage('rows')} >Recommencer</button>
                    </div>
                </div> 
            }
        
        </div>

	)
}

export default Input
