import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../styles/App.css';
import Header from './Header.js';
import Footer from './Footer.js';
import Home from './Home.js';
import ItemMode from './ItemMode.js';
import GameMode from './GameMode.js';


function App() {

  return (
		<div >
      <Header />
      <Router>
          <Routes>
            <Route exact path="/"  element={<Home />} />
            <Route exact path="/item"  element={<ItemMode />} />
            <Route exact path="/game"  element={<GameMode />} />
          </Routes> 
      </Router>  
      <hr />
      <Footer />
		</div>
	)
}

export default App
