import '../styles/Grille.css';

function Grille({rows, rowsUpdate, gameNow}) {
    const anyColorMatch = (colors1, colors2) => {
        return colors2.some(color => colors1.includes(color));
    };

    const allColorsMatch = (colors1, colors2) => {
        return colors1.every(color => colors2.includes(color));
    };
    
    const extraColorsInSecond = (colors1, colors2) => {
        return colors1.every(color => colors2.includes(color)) && colors2.some(color => !colors1.includes(color));
    };

    return (
		<div className="grille">
            <div className="grille-row grille-row-head">
                <div className="grille-col">GAME</div>
                <div className="grille-col">YEAR</div>
                <div className="grille-col">PLATFORM</div>
                <div className="grille-col">SALES</div>
                <div className="grille-col">NOTE</div>
                <div className="grille-col">TYPE</div>
                <div className="grille-col">DEV</div>
            </div>
            {rows.map(({ rowsLength, id_jeu, nom_jeu, annee_jeu, plateforme_jeu, nb_vente_jeu, note_jeu, type_jeu, dev_jeu, img_jeu }) => {
                // Convertir les chaînes de caractères en tableaux
                // let couleurs1 = color.split(',').map(couleur => couleur.trim());
                /*let couleurs1 = gameNow.couleur_item
                let couleurs2 = color
                console.log('couleurs1', couleurs1, 'couleurs2', couleurs2)

                couleurs1 = couleurs1 ? couleurs1.includes(',') ? couleurs1.split(',').map(couleur => couleur.trim()) : [couleurs1.trim()] : null;
                couleurs2 = couleurs2 ? couleurs2.includes(',') ? couleurs2.split(',').map(couleur => couleur.trim()) : [couleurs2.trim()] : null;

                // Vérifier si l'une des couleurs de couleurs2 est dans couleurs1
                let couleurTrouvee = couleurs2 && couleurs1 ? anyColorMatch(couleurs1, couleurs2) : false;
                // Vérifier si toutes les couleurs de couleurs1 sont dans couleurs2
                let toutesCouleursTrouvees = couleurs2 && couleurs1 ? allColorsMatch(couleurs1, couleurs2): false;
                // Vérifier si toutes les couleurs de couleurs1 sont dans couleurs2
                let couleursSupplementaires = couleurs2 && couleurs1 ? extraColorsInSecond(couleurs1, couleurs2): false;*/


                return (
                    <div className="grille-row" key={rowsLength}>
                        
                        <div className={`grille-cell ${id_jeu === gameNow.id_item ? 'green' : 'red'}`} >
                            <div className='grille-cell-detail'>
                                <span><img src={`assets/${img_jeu}`} alt={nom_jeu} /></span><br />
                                <span>{nom_jeu}</span>
                            </div>
                        </div>
                        { annee_jeu === gameNow.annee_jeu ?
                            <div className='grille-cell green'>{annee_jeu}</div>
                            : annee_jeu < gameNow.annee_jeu ? 
                                <div className='grille-cell red after'>{annee_jeu} <img src='assets/arrow_up.png' alt='arrow_up' /></div>
                                : <div className='grille-cell red before'>{annee_jeu} <img src='assets/arrow_down.png' alt='arrow_down' /></div>
                        }
                        <div className={`grille-cell ${plateforme_jeu === gameNow.plateforme_jeu ? 'green' : 'red'}`} >{plateforme_jeu}</div>
                        { 
                            nb_vente_jeu === gameNow.nb_vente_jeu ?
                                <div className='grille-cell green'>{nb_vente_jeu}</div>
                            : nb_vente_jeu < (gameNow.nb_vente_jeu === 'Unknown' ? 0 : gameNow.nb_vente_jeu) ? 
                                <div className='grille-cell red after'>{nb_vente_jeu} <img src='assets/arrow_up.png' alt='arrow_up' /></div>
                            : <div className='grille-cell red before'>{nb_vente_jeu} <img src='assets/arrow_down.png' alt='arrow_down' /></div>
                        }
                        { 
                            note_jeu === gameNow.note_jeu ?
                                <div className='grille-cell green'>{note_jeu}</div>
                            : note_jeu < (gameNow.note_jeu === 'Tdb' ? 0 : gameNow.note_jeu) ? 
                                <div className='grille-cell red after'>{note_jeu} <img src='assets/arrow_up.png' alt='arrow_up' /></div>
                            : <div className='grille-cell red before'>{note_jeu} <img src='assets/arrow_down.png' alt='arrow_down' /></div>
                        }
                        <div className={`grille-cell ${type_jeu === gameNow.type_jeu ? 'green' : 'red'}`} >{type_jeu}</div>
                        <div className={`grille-cell ${dev_jeu === gameNow.dev_jeu ? 'green' : 'red'}`} >{dev_jeu}</div>
                        { /* <div className={`grille-cell ${toutesCouleursTrouvees && !couleursSupplementaires ? 'green' : couleurTrouvee ? 'orange' : 'red'}`}>{color}</div> */ }
                    </div>
                );
            })}
        </div>
	)
}

export default Grille
