import '../styles/Prev.css';
import '../styles/Grille.css';

function Rules() {

	return (
        <div>
            <h2>Rules</h2>
            <p>An item is randomly determined every day. Try to find it by guessing items.</p>
            <div className="grille">
                <div className="grille-row">
                    <div className='grille-cell green'>Correct</div>
                    <div className='grille-cell red'>Incorrect</div>
                    <div className='grille-cell orange'>Partiel</div>
                    <div className='grille-cell red before'>Befor<img src='assets/arrow_down.png' alt='arrow_down' /></div>
                    <div className='grille-cell red after'>After<img src='assets/arrow_up.png' alt='arrow_up' /></div>
                </div>
            </div>
        </div>
	)
}

export default Rules
