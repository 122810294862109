import '../styles/Grille.css';

function Grille({rows, rowsUpdate, itemNow}) {
    console.log('rows', rows, 'itemNow', itemNow)
	
    const anyColorMatch = (colors1, colors2) => {
        return colors2.some(color => colors1.includes(color));
    };

    const allColorsMatch = (colors1, colors2) => {
        return colors1.every(color => colors2.includes(color));
    };
    
    const extraColorsInSecond = (colors1, colors2) => {
        return colors1.every(color => colors2.includes(color)) && colors2.some(color => !colors1.includes(color));
    };

    return (
		<div className="grille">
            <div className="grille-row grille-row-head">
                <div className="grille-col">ITEM</div>
                <div className="grille-col">GAME</div>
                <div className="grille-col">PLATFORM</div>
                <div className="grille-col">TYPE</div>
                <div className="grille-col">COLORS</div>
                <div className="grille-col">LOCATION</div>
            </div>
            {rows.map(({ rowsLength, id, item, game, anneeJeu, type, color, location, plateforme_jeu, img_item }) => {
                // Convertir les chaînes de caractères en tableaux
                // let couleurs1 = color.split(',').map(couleur => couleur.trim());
                let couleurs1 = itemNow.couleur_item
                let couleurs2 = color
                console.log('couleurs1', couleurs1, 'couleurs2', couleurs2)

                couleurs1 = couleurs1 ? couleurs1.includes(',') ? couleurs1.split(',').map(couleur => couleur.trim()) : [couleurs1.trim()] : null;
                couleurs2 = couleurs2 ? couleurs2.includes(',') ? couleurs2.split(',').map(couleur => couleur.trim()) : [couleurs2.trim()] : null;

                // Vérifier si l'une des couleurs de couleurs2 est dans couleurs1
                let couleurTrouvee = couleurs2 && couleurs1 ? anyColorMatch(couleurs1, couleurs2) : false;
                // Vérifier si toutes les couleurs de couleurs1 sont dans couleurs2
                let toutesCouleursTrouvees = couleurs2 && couleurs1 ? allColorsMatch(couleurs1, couleurs2): false;
                // Vérifier si toutes les couleurs de couleurs1 sont dans couleurs2
                let couleursSupplementaires = couleurs2 && couleurs1 ? extraColorsInSecond(couleurs1, couleurs2): false;


                return (
                    <div className="grille-row" key={rowsLength}>
                        
                        { /* <GrilleRow
                            id={id}
                            item={item}
                            game={game}
                            type={type}
                            color={color}
                            location={location}
                        /> */ }
                        
                        <div className={`grille-cell ${id === itemNow.id_item ? 'green' : 'red'}`} >
                            <div className='grille-cell-detail'>
                                <span><img src={`assets/${img_item}`} alt={item.nom_item} /></span><br />
                                <span>{item}</span>
                            </div>
                        </div>
                        { game === itemNow.nom_jeu ?
                            <div className='grille-cell green'>{game} ({anneeJeu})</div>
                            : anneeJeu < itemNow.annee_jeu ? 
                                <div className='grille-cell red after'>{game} ({anneeJeu}) <img src='assets/arrow_up.png' alt='arrow_up' /></div>
                                : <div className='grille-cell red before'>{game} ({anneeJeu}) <img src='assets/arrow_down.png' alt='arrow_down' /></div>
                        }
                        <div className={`grille-cell ${plateforme_jeu === itemNow.plateforme_jeu ? 'green' : 'red'}`} >{plateforme_jeu}</div>
                        <div className={`grille-cell ${type === itemNow.type_item ? 'green' : 'red'}`} >{type}</div>
                        <div className={`grille-cell ${toutesCouleursTrouvees && !couleursSupplementaires ? 'green' : couleurTrouvee ? 'orange' : 'red'}`}>{color}</div>
                        <div className={`grille-cell ${location === itemNow.localisation_item ? 'green' : 'red'}`} >{location}</div>
                    </div>
                );
            })}
        </div>
	)
}

export default Grille
